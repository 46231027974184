import { useEffect, useState } from "react";

import { useRequestSong } from "../../context/RequestOrderContext";

import SpinnerFullPage from "../../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ReusableComponents/ErrorMessage/ErrorMessage";
import SuccessMessage from "../../components/ReusableComponents/SuccessMessage";

function PayPalPaymentSuccessPage() {
  const { backendURL } = useRequestSong();
  const [loading, setLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(
    function () {
      const URL = window.location.href;
      console.log();
      if (!URL.split("=")[1]) return;
      const ORDER_ID = URL.split("=")[1].split("&")[0];

      if (URL.includes("session_id=cs_live")) {
        return;
      }
      async function capturePayment() {
        try {
          setLoading(true);
          const response = await fetch(`${backendURL}paypal/validate/`, {
            method: "POST",
            body: JSON.stringify({
              id: ORDER_ID,
            }),
          });
          const data = await response.json();
          if (!response.ok) {
            const errorMsg = data["errorMsg"];
            console.log(errorMsg);
            throw new Error(
              errorMsg
                ? errorMsg
                : "Something went wrong while capturing your payment. Contact customer support to check what is wrong."
            );
          }
          console.log(data);
          setErrorMessage(null);
        } catch (error) {
          setErrorMessage(error.message);
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
      capturePayment();
    },
    [backendURL]
  );

  if (errorMessage) return <ErrorMessage errorMessage={errorMessage} />;
  if (loading) return <SpinnerFullPage />;
  return (
    <SuccessMessage
      successMessage={
        "Thank you for your order! Your payment has been successfully processed and your chosen artist will start work on your custom song right away. If you have any questions or need further assistance, please don't hesitate to contact our customer support team at hello@oursongrequest.com"
      }
    />
  );
}

export default PayPalPaymentSuccessPage;
